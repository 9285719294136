import request from "../utils/request";

// 根据组织ID获取组织信息
export function organizationInfo(organizationId) {
    return request({
        url: `/user/api/organization/organizationInfo`,
        method: "GET",
        params: {
            organizationId
        }
    });
}

// 根据组织ID获取组织文章列表
export function organizationArticleList(data) {
    return request({
        url: `/article/api/organizationArticleList`,
        method: "POST",
        data
    });
}

// 根据组织ID获取组织成员数
export function organizationMembers(organizationId) {
    return request({
        url: `/user/api/organization/organizationMembers`,
        method: "GET",
        params: {
            organizationId
        }
    });
}

// 申请加入组织
export function applyJoinOrganization(organizationId) {
    return request({
        url: `/user/organization/applyJoinOrganization`,
        method: "GET",
        params: {
            organizationId
        }
    });
}

// 退出组织
export function exitOrganization(organizationId) {
    return request({
        url: `/user/organization/exitOrganization`,
        method: "GET",
        params: {
            organizationId
        }
    });
}
