import request from "../utils/request";

// 获取用户粉丝列表
export function getUserFansList(data = {}) {
    return request({
        url: `/user/fans/list`,
        method: "POST",
        data
    });
}

// 检查userid2是否是userid1的粉丝
export function checkIsFans(userId1, userId2) {
    return request({
        url: `/user/fans/checkIsFans`,
        method: "GET",
        params: {
            userId1,
            userId2
        }
    });
}

// 用户关注或取消关注
export function follow(data = {}) {
    return request({
        url: `/user/fans/follow`,
        method: "POST",
        data
    });
}
